import axios from 'axios';

export const isProd = true;
// export const API_URL = isProd
//   ? 'https://api-hashtag.economyescapes.com/v1'
//   : 'https://admin-hashtaggeneration.rootcode.software/v1';
export const API_URL = isProd
  ? 'https://api-dashboard.hashtaggeneration.org/v1'
  : 'http://localhost:3000/v1';
export const AWS_URL = isProd
  ? 'https://hashtag-gen-prod.s3.us-east-2.amazonaws.com'
  : 'https://hashtag-gen-dev.s3.us-east-2.amazonaws.com';

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config: any) => {
    const tokenString = localStorage.getItem('access_token');
    const token = tokenString !== null ? JSON.parse(tokenString) : null;
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      localStorage.clear();
      window.location.replace('/');
    }
    return Promise.reject(error);
  }
);

export const sendAuthenticatedGetRequest = (url: string, params: any = null) => {
  url = params ? url + '?' + constructUrlWithParams(params) : url;
  const response = axiosApiInstance.get(url, params);
  return response;
};

export const sendAuthenticatedPostRequest = (url: string, data: any = null) => {
  const response = axiosApiInstance.post(url, data);
  return response;
};

export const sendAuthenticatedPutRequest = (url: string, data: any = null) => {
  const response = axiosApiInstance.put(url, data);
  return response;
};

export const sendGetRequest = (url: string, params: any = null) => {
  url = params ? url + '?' + constructUrlWithParams(params) : url;
  const response = axios.get(url, params);
  return response;
};

export const sendPostRequest = (url: string, data: any = null) => {
  const response = axios.post(url, data, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response;
};

const constructUrlWithParams = (params: any) => {
  //params serialization
  const esc = encodeURIComponent;
  const query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  return query;
};
